<template>
    <el-row class="warp">
        <el-col :span="24" class="warp-breadcrum">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
                <el-breadcrumb-item>系统管理</el-breadcrumb-item>
                <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>


        <!--<el-col :span="24" class="warp-main">-->
        <!--<el-col :span="24" class="toolbar" style="padding-bottom: 0px;">-->
        <!--<el-form :inline="true">-->
        <!--&lt;!&ndash;<el-form-item>&ndash;&gt;-->
        <!--&lt;!&ndash;<el-button type="primary" size="small" @click="showAddTopDialog" v-if="permissionButton.addTop">添加顶级资源</el-button>&ndash;&gt;-->
        <!--&lt;!&ndash;</el-form-item>&ndash;&gt;-->
        <!--<el-form-item style="float:right;">-->
        <!--<el-switch v-model="props.isFold"/>-->
        <!--&lt;!&ndash;<el-button type="success" size="small">刷新数据</el-button>&ndash;&gt;-->
        <!--</el-form-item>-->
        <!--<el-form-item>-->
        <!--&lt;!&ndash;<el-button type="danger" size="small">删除</el-button>&ndash;&gt;-->
        <!--</el-form-item>-->
        <!--</el-form>-->
        <!--</el-col>-->
        <!--</el-col>-->

        <zk-table
                ref="table"
                sum-text="sum"
                index-text="#"
                :data="table_data"
                :columns="columns"
                :stripe="props.stripe"
                :border="props.border"
                :show-header="props.showHeader"
                :show-summary="props.showSummary"
                :show-row-hover="props.showRowHover"
                :show-index="props.showIndex"
                :tree-type="props.treeType"
                :is-fold="props.isFold"
                :expand-type="props.expandType"
                :selection-type="props.selectionType" v-loading="loading">
            <template slot="permissionIcon" slot-scope="scope">
                <i :class="scope.row.permissionIcon"></i>
            </template>
            <template slot="permissionTypeId" slot-scope="scope">
                <span v-if="scope.row.permissionTypeId==1">菜单</span>
                <span v-else>按钮</span>
            </template>
            <template slot="action" slot-scope="scope">
                <el-button type="default" @click="showEditDialog(scope.row)" size="small" v-if="permissionButton.edit">
                    编辑
                </el-button>
                <!--<el-button type="default" @click="showAddDialog(scope.row)" size="small" v-if="scope.row.permissionTypeId==1 && permissionButton.addNext">添加下级</el-button>-->
                <!--<el-button type="danger" @click="delMenu(scope.row)" size="small" v-if="permissionButton.del">删除</el-button>-->
            </template>
        </zk-table>

        <el-dialog title="新增顶级菜单" :visible.sync="addTopFormVisible" width="30%" :close-on-click-modal="false">
            <el-form :model="addTopForm" label-width="80px" ref="addTopForm" :rules="addTopFormRules">
                <el-form-item label="资源名称" prop="permissionName">
                    <el-input v-model="addTopForm.permissionName" placeholder="请输入资源名称"></el-input>
                </el-form-item>
                <el-form-item label="资源URL" prop="permissionUrl">
                    <el-input v-model="addTopForm.permissionUrl" placeholder="请输入资源URL"></el-input>
                </el-form-item>
                <el-form-item label="权限控制" prop="permissionResource">
                    <el-input v-model="addTopForm.permissionResource" placeholder="请输入权限控制"></el-input>
                </el-form-item>
                <el-form-item label="资源说明" prop="permissionDescription">
                    <el-input v-model="addTopForm.permissionDescription" placeholder="请输入资源说明"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addTopFormVisible = false">取消</el-button>
                <el-button type="primary" @click.native="addTopSubmit" :loading="addTopLoading">提交</el-button>
            </div>
        </el-dialog>

        <el-dialog title="新增下级" :visible.sync="addFormVisible" width="30%" :close-on-click-modal="false">
            <el-form :model="addForm" label-width="80px" ref="addForm" :rules="addFormRules">
                <el-form-item label="资源名称" prop="permissionName">
                    <el-input v-model="addForm.permissionName" placeholder="请输入资源名称"></el-input>
                </el-form-item>
                <el-form-item label="父菜单" prop="permissionParentId">
                    <el-input v-bind:value="pPermissionName" readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="资源URL" prop="permissionUrl">
                    <el-input v-model="addForm.permissionUrl" placeholder="请输入资源URL"></el-input>
                </el-form-item>
                <el-form-item label="资源类型" prop="permissionTypeId">
                    <el-radio-group v-model="addForm.permissionTypeId">
                        <el-radio :label="1">功能</el-radio>
                        <el-radio :label="2">按钮</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="权限控制" prop="permissionResource">
                    <el-input v-model="addForm.permissionResource" placeholder="请输入权限控制"></el-input>
                </el-form-item>
                <el-form-item label="资源说明" prop="permissionDescription">
                    <el-input v-model="addForm.permissionDescription" placeholder="请输入资源说明"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit" :loading="addLoading">提交</el-button>
            </div>
        </el-dialog>

        <el-dialog title="编辑" :visible.sync="editFormVisible" width="30%" :close-on-click-modal="false">
            <el-form :model="editForm" label-width="100px" ref="editForm" :rules="editFormRules">
                <el-form-item label="资源名称" prop="permissionName">
                    <el-input v-model="editForm.permissionName" placeholder="请输入资源名称" maxlength="50"></el-input>
                </el-form-item>
                <el-form-item label="资源URL" prop="permissionUrl">
                    <el-input v-model="editForm.permissionUrl" :disabled="true" id="editPermissionUrl1"
                              placeholder="请输入资源URL" maxlength="200"></el-input>
                </el-form-item>
                <el-form-item label="资源图标" prop="permissionUrl">
                    <el-input v-model="editForm.permissionIcon" id="editPermissionUrl1" placeholder="请输入资源图标"
                              maxlength="200"></el-input>
                </el-form-item>
                <el-form-item label="资源类型" prop="permissionTypeId">
                    <el-radio-group v-model="editForm.permissionTypeId">
                        <el-radio :label="1">功能</el-radio>
                        <el-radio :label="2">按钮</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="权限控制" prop="permissionResource">
                    <el-input v-model="editForm.permissionResource" placeholder="请输入权限控制" maxlength="200"></el-input>
                </el-form-item>
                <el-form-item label="资源说明" prop="permissionDescription">
                    <el-input v-model="editForm.permissionDescription" placeholder="请输入资源说明" maxlength="200"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="editFormVisible = false">取消</el-button>
                <el-button type="primary" @click.native="editSubmit" :loading="editLoading">提交</el-button>
            </div>
        </el-dialog>

    </el-row>
</template>

<script>
    import {queryAdminPermissions, getAllParentPermission, reqAddMenu, reqEditMenu, isExistChilds, reqDeleteMenu} from '../../../api/system-api';
    import permission from '../../../common/permission';

    export default {
        data() {
            return {
                loading: true,
                props: {
                    stripe: true,
                    border: true,
                    showHeader: true,
                    showSummary: false,
                    showRowHover: false,
                    showIndex: true,
                    treeType: true,
                    isFold: false,
                    selectionType: false,
                    expandType: false,
                },
                permissionButton: new permission.PermissionButton,
                columns: [
                    {
                        label: '资源名称',
                        prop: 'permissionName',
                        headerAlign: 'center',
                        width: '240px',
                    },
                    {
                        label: '资源URL',
                        prop: 'permissionUrl',
                        minWidth: '260px',
                        headerAlign: 'center',
                    },
                    {
                        label: '资源图标',
                        prop: 'permissionIcon',
                        type: 'template',
                        template: 'permissionIcon',
                        width: '100px',
                        headerAlign: 'center',
                        align: 'center',
                    },
                    {
                        label: '权限控制',
                        prop: 'permissionResource',
                        width: '200px',
                        headerAlign: 'center',
                    },
                    {
                        label: '资源类型',
                        prop: 'permissionTypeId',
                        type: 'template',
                        template: 'permissionTypeId',
                        width: '100px',
                        headerAlign: 'center',
                        align: 'center',
                    },
                    {
                        label: '资源说明',
                        prop: 'permissionDescription',
                        minWidth: '200px',
                        headerAlign: 'center',
                    },
                    {
                        label: '创建时间',
                        prop: 'formatCreateTime',
                        width: '180px',
                        headerAlign: 'center',
                        align: 'center',
                    },
                    {
                        label: '操作',
                        prop: 'operate',
                        width: '120px',
                        type: 'template',
                        template: 'action',
                        headerAlign: 'center',
                        align: 'center',
                    },
                ],
                table_data: [],
                addTopFormVisible: false,//新增界面是否显示
                addTopLoading: false,
                addTopForm: {
                    permissionName: '',
                    permissionParentId: '1',
                    permissionSubId: '',
                    permissionUrl: '',
                    permissionTypeId: '1',
                    permissionResource: '',
                    permissionDescription: '',
                },

                addTopFormRules: {
                    permissionName: [
                        {required: true, message: '请输入资源名称', trigger: 'blur'},
                        {min: 1, max: 50, message: '名称不能超过50个字符', trigger: 'blur'},
                    ],
                    permissionUrl: [
                        {required: true, message: '请输入资源URL', trigger: 'blur'},
                        {min: 1, max: 255, message: '资源URL不能超过255个字符', trigger: 'blur'}
                    ],
                    permissionResource: [
                        {required: true, message: '请输入权限控制', trigger: 'blur'},
                        {min: 1, max: 200, message: '序号不能超过200个字符', trigger: 'blur'}
                    ],
                    permissionDescription: [
                        {min: 1, max: 250, message: '备注不能超过250个字符', trigger: 'blur'}
                    ],
                },
                addFormVisible: false,//新增界面是否显示
                addLoading: false,
                pPermissionName: '',
                addForm: {
                    permissionName: '',
                    permissionParentId: '',
                    permissionSubId: '',
                    permissionUrl: '',
                    permissionTypeId: '',
                    permissionResource: '',
                    permissionDescription: '',
                },
                addFormRules: {
                    permissionName: [
                        {required: true, message: '请输入资源名称', trigger: 'blur'},
                        {min: 1, max: 50, message: '名称不能超过50个字符', trigger: 'blur'},
                    ],
                    permissionParentId: [],
                    permissionUrl: [
                        {required: true, message: '请输入资源URL', trigger: 'blur'},
                        {min: 1, max: 200, message: '资源URL不能超过200个字符', trigger: 'blur'}
                    ],
                    permissionTypeId: [],
                    permissionIcon: [
                        {required: true, message: '请输入资源图标', trigger: 'blur'},
                        {min: 1, max: 200, message: '资源图标不能超过200个字符', trigger: 'blur'}
                    ],
                    permissionResource: [
                        {required: true, message: '请输入权限控制', trigger: 'blur'},
                        {min: 1, max: 200, message: '序号不能超过200个字符', trigger: 'blur'}
                    ],
                    permissionDescription: [
                        {min: 1, max: 200, message: '备注不能超过200个字符', trigger: 'blur'}
                    ],
                },
                editFormVisible: false,
                editLoading: false,
                editForm: {
                    permissionId: '',
                    permissionName: '',
                    permissionParentId: '',
                    permissionSubId: '',
                    permissionUrl: '',
                    permissionTypeId: '',
                    permissionResource: '',
                    permissionDescription: '',
                    permissionIcon: ''
                },
                editFormRules: {
                    permissionName: [
                        {required: true, message: '请输入资源名称', trigger: 'blur'},
                        {min: 1, max: 50, message: '名称不能超过50个字符', trigger: 'blur'},
                    ],
                    permissionParentId: [],
                    permissionUrl: [
                        {required: true, message: '请输入资源URL', trigger: 'blur'},
                        {min: 1, max: 255, message: '资源URL不能超过255个字符', trigger: 'blur'}
                    ],
                    permissionTypeId: [],
                    permissionResource: [
                        {required: true, message: '请输入权限控制', trigger: 'blur'},
                        {min: 1, max: 250, message: '序号不能超过250个字符', trigger: 'blur'}
                    ],
                    permissionDescription: [
                        {min: 1, max: 250, message: '备注不能超过250个字符', trigger: 'blur'}
                    ],
                },
                parentMenus: [],
                defaultProps: {
                    children: 'children',
                    label: 'permissionName'
                },

            };
        },
        computed: {
            propList() {
                return Object.keys(this.props).map(item => ({
                    name: item,
                }));
            },
        },
        methods: {
            getMenus() {
                let that = this;
                that.loading = true;
                queryAdminPermissions({}).then((res) => {
                    that.loading = false;
                    that.table_data = res.data.data;
                })
            },
            showAddTopDialog: function () {
                this.getParentMenuList();
                this.addTopFormVisible = true;
                this.addForm = {
                    permissionName: '',
                    permissionParentId: 1,
                    permissionSubId: -1,
                    permissionUrl: '',
                    permissionTypeId: 1,
                    permissionResource: '',
                    permissionDescription: '',
                };
            },
            addTopSubmit: function () {
//        this.$refs.addForm.validate((valid) => {
//          if (valid) {
                this.addTopLoading = true;
                let para = Object.assign({}, this.addTopForm);
                reqAddMenu(para).then(() => {
                    this.addTopLoading = false;
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    });
                    this.$refs['addTopForm'].resetFields();
                    this.addTopFormVisible = false;
                    this.getMenus();
                });
//          }
//        });
            },
            showAddDialog: function (row) {
//        this.getParentMenuList();
                this.addFormVisible = true;
                this.pPermissionName = row.permissionName;
                this.addForm = {
                    permissionName: '',
                    permissionParentId: row.permissionId,
                    permissionSubId: -1,
                    permissionUrl: '',
                    permissionTypeId: 1,
                    permissionResource: '',
                    permissionDescription: '',
                };
            },
            addSubmit: function () {
                this.$refs.addForm.validate((valid) => {
                    if (valid) {
                        this.addLoading = true;
                        let para = Object.assign({}, this.addForm);
                        para.roleId = 2;
                        reqAddMenu(para).then(() => {
                            this.addLoading = false;
                            this.$message({
                                message: '提交成功',
                                type: 'success'
                            });
                            this.$refs['addForm'].resetFields();
                            this.addFormVisible = false;
                            this.getMenus();
                        });
                    }
                });
            },
            showEditDialog: function (row) {
//        this.getParentMenuList();
                this.editFormVisible = true;
//        this.editForm = Object.assign({}, row);
                this.editForm = {
                    permissionId: row.permissionId,
                    permissionName: row.permissionName,
                    permissionParentId: row.permissionParentId,
                    permissionSubId: row.permissionSubId,
                    permissionUrl: row.permissionUrl,
                    permissionTypeId: row.permissionTypeId,
                    permissionResource: row.permissionResource,
                    permissionDescription: row.permissionDescription,
                    permissionIcon: row.permissionIcon
                };
            },
            editSubmit: function () {
//        this.$refs.editForm.validate((valid) => {
//          if (valid) {
                this.$confirm('确认提交吗？', '提示', {}).then(() => {
                    this.editLoading = true;
                    let para = Object.assign({}, this.editForm);
//              para.originalPermissionName=para.permissionName;
                    reqEditMenu(para).then(() => {
                        this.editLoading = false;
                        this.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                        this.$refs['editForm'].resetFields();
                        this.editFormVisible = false;
                        this.getMenus();
                    });
                }).catch(() => {
                    console.log("取消提交");
                })
//          }
//        });
            },
            delMenu: function (row) {
                this.$confirm('确认删除该记录吗?', '提示', {type: 'warning'}).then(() => {
                    this.listLoading = true;
                    let para = {permissionId: row.permissionId};
                    isExistChilds(para).then((res) => {
                        if (!res.data.data) {
                            reqDeleteMenu(para).then((res) => {
                                this.listLoading = false;
                                this.$message({
                                    message: res.data.msg,
                                    type: 'success'
                                });
                                this.getMenus();
                            });
                        } else {
                            this.$message({
                                message: '含有子菜单或者按钮不能删除！',
                                type: 'warning'
                            });
                        }
                    });
                }).catch(() => {
                });
            },
            getParentMenuList: function () {
                var that = this;
                getAllParentPermission({}).then((res) => {
                    that.parentMenus = res.data.data;
                    that.parentMenus.unshift({permissionId: 1, permissionName: '顶级菜单'});
                });
            },
        },
        mounted() {
            var permissionId = this.$route.query.permissionId;
            permission.getPermissionButton(permissionId, this.permissionButton);
            this.getMenus();
        }
    };
</script>
